exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/aboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-bb-glow-js": () => import("./../../../src/pages/bb-glow.js" /* webpackChunkName: "component---src-pages-bb-glow-js" */),
  "component---src-pages-elektroporacio-js": () => import("./../../../src/pages/elektroporacio.js" /* webpackChunkName: "component---src-pages-elektroporacio-js" */),
  "component---src-pages-elerhetosegek-js": () => import("./../../../src/pages/elerhetosegek.js" /* webpackChunkName: "component---src-pages-elerhetosegek-js" */),
  "component---src-pages-gepi-kezelesek-js": () => import("./../../../src/pages/gepi-kezelesek.js" /* webpackChunkName: "component---src-pages-gepi-kezelesek-js" */),
  "component---src-pages-gyantazas-js": () => import("./../../../src/pages/gyantazas.js" /* webpackChunkName: "component---src-pages-gyantazas-js" */),
  "component---src-pages-hazirend-js": () => import("./../../../src/pages/hazirend.js" /* webpackChunkName: "component---src-pages-hazirend-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kezelesek-js": () => import("./../../../src/pages/kezelesek.js" /* webpackChunkName: "component---src-pages-kezelesek-js" */),
  "component---src-pages-klasszikus-kezelesek-js": () => import("./../../../src/pages/klasszikusKezelesek.js" /* webpackChunkName: "component---src-pages-klasszikus-kezelesek-js" */),
  "component---src-pages-mezoterapias-kezeles-js": () => import("./../../../src/pages/mezoterapias-kezeles.js" /* webpackChunkName: "component---src-pages-mezoterapias-kezeles-js" */),
  "component---src-pages-pedikur-js": () => import("./../../../src/pages/pedikur.js" /* webpackChunkName: "component---src-pages-pedikur-js" */),
  "component---src-pages-pricelist-js": () => import("./../../../src/pages/pricelist.js" /* webpackChunkName: "component---src-pages-pricelist-js" */),
  "component---src-pages-relaxalo-kezelesek-js": () => import("./../../../src/pages/relaxaloKezelesek.js" /* webpackChunkName: "component---src-pages-relaxalo-kezelesek-js" */),
  "component---src-pages-specialis-arckezelesek-js": () => import("./../../../src/pages/specialisArckezelesek.js" /* webpackChunkName: "component---src-pages-specialis-arckezelesek-js" */),
  "component---src-pages-szemoldok-szempilla-js": () => import("./../../../src/pages/szemoldokSzempilla.js" /* webpackChunkName: "component---src-pages-szemoldok-szempilla-js" */),
  "component---src-pages-termikus-ionizacio-js": () => import("./../../../src/pages/termikus-ionizacio.js" /* webpackChunkName: "component---src-pages-termikus-ionizacio-js" */)
}

